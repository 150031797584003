import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";

const Loading = lazy(() => import("./pages/Loading.js"));
const Spin = lazy(() => import("./pages/Spin.js"));
const Reward = lazy(() => import("./pages/Reward.js"));

function App() {

  return (
    <div className="App" >
      {" "}
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/spin" element={<Spin />} />
            <Route path="/reward" element={<Reward />} />
            <Route path="*" element={<Navigate to="/spin" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div >
  );
}

export default App;
